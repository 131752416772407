import React, { useState, useEffect, useCallback } from "react";
import { withI18n } from "react-i18next";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Button, Link, Modal, Typography } from "../Common";
import GiftIcon from "images/gift-pink.svg";
import { connect } from "react-redux";
import { iRootState } from "store";
import { IPurchaseAttempt } from "shared/api/dtos/IStripeDto";
// @ts-ignore
import { getCurUser, getWebSessionKeyFromAuthToken, getSessionDataFromAuthToken, getAuthToken } from 'cwb-react'
import { getWebSessionKey } from "helpers";

type Props = {
  t: any;
  onClose: any;
  trialPeriod: any;
} & RouteComponentProps & DispatchProps;

const ModalTrialSignup: React.FC<Props> = ({
  t,
  history,
  location,
  purchase,
  clientId,
  onClose,
  trialPeriod,
}) => {

  useEffect(() => {
  }, []);

  return (
    <>
      <StyledModal>
        <div className="icon-question">
          <span><img src={GiftIcon}/> </span>
        </div>
        <Typography className="question" align="center" component="h1" variant="h2">
          {t("Are you sure?")}
        </Typography>
        <Typography className="description" align="center" color="darkGrey" variant="h4">
          {t(
            `By opting out of the three-month FREE TRIAL, you’re losing out on Casting Workbook’s expansive suite of tools.`
          )}
        </Typography>
        <Typography className="description-2">
          {t(`This cannot be undone. The trial can be canceled at anytime before ${trialPeriod?.endDate} so you can take advantage of PRO features designed for pro actors`)}
        </Typography>
        <div className="trial-btn-wrapper">
          <StyledButton
            className="free-trial"
            onClick={() => {}}>
            {t(`Get the FREE 3-month PRO Trial`)}
          </StyledButton>
          <StyledButton
            className="free-lite"
            onClick={() => {}}>
            {t(`Opt-out and sign-up for LITE`)}
          </StyledButton>
        </div>
      </StyledModal>
      <Backdrop onClick={onClose} />
    </>
  );
};

const Backdrop = styled.div`
  background: rgba(0,0,0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 21;
`;

const StyledModal = styled(Modal)`
  width: 700px;
  position: fixed;
  margin: 0 auto;
  z-index: 22;
  left: 0;
  right: 0;
  height: auto;
  top: calc(100% - 600px);
  border-radius: 25px;
  border-top: 0px;
  padding-left: 50px;
  padding-right: 50px;
  .trial-btn-wrapper{
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    .free-trial, .free-lite{
      min-height: 60px;
      border-radius: 15px;
      font-weight: normal;
      margin-top: 20px;
    }
    .free-trial{
      margin-right: 10px;
      background: #2B549C;
    }
    .free-lite{
      margin-left: 10px;
      color: #FF5F75;
      background: transparent;
      border: 1px solid #FF5F75;
    }
  }
  .description, .description-2{
    text-align: left;
  }
  .description-2{
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
  }
  .icon-question{
    text-align: center;
    padding-top: 33px;
    > span{
      width: 50px;
      height: 50px;
      background: #FFEBEE;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      img{
        width: 25px;
        height: auto;
      }
    }
  }
  .question{
    margin-top: 13px;
    margin-bottom: 26px;
  }
  .description{
    border-top: 1px solid rgba(0,0,0, 0.25);
    padding-top: 29px;
    margin-bottom: 20px;
  }
  .description-2{
    border-bottom: 1px solid rgba(0,0,0, 0.25);
    padding-bottom: 29px;
  }
`;

const StyledImg = styled.img`
  margin: ${(p) => p.theme.spacing(2, 0, 4)};
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  &&,
  &&:hover {
    text-decoration: underline;
  }
`;

const StyledButton = styled(Button)`
  min-width: 200px;
  margin-top: ${(p) => p.theme.spacing(5)};
`;


interface DispatchProps {
  purchase: IPurchaseAttempt;
  clientId: any;
}

const mapStateToProps = (state: iRootState) => ({
  purchase: state.stripeModel.purchase,
  clientId: state.actorModel.clientId,
});

export default withI18n()(withRouter(connect(
  mapStateToProps,
  null
)(ModalTrialSignup)));
import React from "react";
import qs from "query-string";
import { getI18n, withI18n } from "react-i18next";
import styled from "styled-components";
// @ts-ignore
import { getCountryCode } from "../../helpers/index";
import { withRouter } from "react-router-dom";
import { Button } from 'components/Common';
import CheckIcon from 'images/check.svg';
import BellIcon from 'images/bell.svg';
import GiftIcon from 'images/gift.svg';
import GiftWhiteIcon from 'images/gift-white.svg';
import StarIcon from 'images/star.svg';

import { iRootState } from "../../store";
import { connect } from "react-redux";
import ModalTrialSignup from "./ModalTrialSignup";

interface IState {
  modalTrial: boolean;
}

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history: any;
  location: any;
  match: any;
}

class TrialSignup extends React.Component<IProps, IState> {
  public state: IState = {
    modalTrial: false,
  };

  async componentWillMount() {
  }

  async componentDidMount() { 
  }

  get90DayFreeTrialStart = () => {
    const now = new Date();
    const trialStart = new Date(now.getTime());
    trialStart.setDate(now.getDate() + 90);
    
    const formatDate = (date: any) => {
      return date.toLocaleDateString('en-US', { 
        month: 'long', 
        day: 'numeric', 
        year: 'numeric' 
      });
    };

    return {
      startDate: formatDate(now),
      endDate: formatDate(trialStart),
      durationDays: 90
    };
  };


  render() {
    const { t, countryCode } = this.props;
    const trialPeriod = this.get90DayFreeTrialStart();

    return (
      <>
        <Wrapper>
          <WrapperColumns>
            <WrapperPricing>
              <WrapperPricingCard className="first">
                <ul>
                  <b>Casting Workbook LITE</b>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>2 Headshots</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>2 Resumes</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>Worldwide Breakdown Submissions</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>Unlimited Self-Tapes</span>
                  </li>
                  <h2>$0 USD<br/><span>/ year</span></h2>
                </ul>
              </WrapperPricingCard>
              <WrapperPricingCard className="second">
                <div className="offer">Special Offer: First Three Months are FREE</div>
                <ul>
                  <b>Casting Workbook PRO</b>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>16 Headshots</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>Unlimited Resumes</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>Worldwide Breakdown Submissions</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>Unlimited Self-Tapes</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>Actor.App - iOS & Android</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>Personal Website (with Unlimited Photos)</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>5 Hours + Demo Reels</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>Built-In Video Editor</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>Unlimited Video Links</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>Virtual Home-check for self-taping</span>
                  </li>
                  <li>
                    <img src={CheckIcon} alt="Check icon" />
                    &nbsp;
                    <span>Portfolio Pitching & Tracking</span>
                  </li>
                  <h2>$99 USD<br/><span>/ year (After Three Months)</span></h2>
                </ul>
              </WrapperPricingCard>
            </WrapperPricing>
            <WrapperPricingTrial>
              <TrialButton onClick={() => {
                this.setState({
                  modalTrial: true
                })
              }}>
                Get your FREE 3-month TRIAL Today
              </TrialButton>
              <span>Opt out of 3-month FREE TRIAL</span>
            </WrapperPricingTrial>
          </WrapperColumns>
          <WrapperSchedule>
            <ul>
              <li className="active">
                <img src={GiftWhiteIcon} />
                <div>
                  <b>Today</b>
                  <span>Your 90 day free-trial starts</span>
                </div>
              </li>
              <li>
                <img src={BellIcon} />
                <div>
                  <b>{trialPeriod?.endDate}</b>
                  <span>Your 90 day free-trial starts</span>
                </div>
              </li>
              <li>
                <img src={StarIcon} />
                <div>
                  <b>{trialPeriod?.startDate}</b>
                  <span>Your subscription starts, unless you’ve cancelled during the trial</span>
                </div>
              </li>
            </ul>
          </WrapperSchedule>
        </Wrapper>
        {this.state.modalTrial && (
          <ModalTrialSignup
            trialPeriod={trialPeriod}
            onClose={() => {
            this.setState({
              modalTrial: false
            })
          }} />
        )}
      </>
    );
  }
}

interface StateProps {
  countryCode: string;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    countryCode: state.referencesModel.countryCode,
  };
}

interface DispatchProps {
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (countryCode: string) => void
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
    setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode
  };
}

export default withI18n()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TrialSignup))
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-bottom: 0px;
  @media all and (max-width: 1023px) {
    
    display: block;
    & > div {
      display:flex;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      max-width: 80% !important;
    }
    & > div > div {
      margin-bottom: 0px !important;
    }

    & > div:last-of-type {
      margin-top: 0px;
      padding-bottom: 40px;
    }
  }

  @media all and (max-width: 768px) {
    display:block;
    & > div {
      max-width: 90% !important;
    }

    & > div:last-of-type {
      margin-top: 20px;
      padding-bottom: 40px;
    }
  }

  @media all and (max-width: 520px) {
    flex-direction: column;
    background-color: white;
    & > div {
      max-width: 99% !important;
      margin-top: 1px !important;
    }
  }
`;

const WrapperSchedule = styled.div`
  display: flex;
  align-items: center;
  ul{
    padding: 0px;
    margin: 0px;
    margin-left: 75px;
    position: relative;
    overflow: hidden;
    img{
      position: absolute;
      left: 10px;
      z-index: 11;
      width: 30px;
      height: auto;
      top: 8px;
    }
    li{
      display: flex;
      margin-bottom: 35px;
      position: relative;
      &:nth-child(3){
      }
      &:nth-child(2){
      }
      &:nth-child(1){
      }
      &:last-of-type{
        &:after{
          display: none;
        }
      }
      &.active{
        &:before{
          background: #007019;
        }
      }
      > div{
        display: flex;
        flex-direction: column;
        width: calc(100% - 100px);
        margin-left: 20px;
      }
      &:before{
        content: '';
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        position: relative;
        z-index: 1;
      }
      &:after{
        content: '';
        height: calc(100% + 35px);
        width: 7px;
        background: #CACACA;
        position: absolute;
        left: 22px;
      }
    }
  }
`;

const WrapperPricingCard = styled.div`
  padding-left: 31px;
  padding-right: 31px;
  min-height: 600px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 20px;
  min-width: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  position: relative;
  b{
    display: block;
    margin-bottom: 40px;
    text-align: left;
  }
  &.first{
    background: #F2F3F5;
    margin-right: 11px;
  }
  &.second{
    background: #EBF8FF;
    margin-left: 11px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    .offer{
      background: #000;
      color: #fff;
      position: relative;
      width: calc(100% + 62px);
      padding-top: 15px;
      padding-bottom: 15px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      font-weight: bold;
      margin-top: -94px;
      margin-bottom: 30px;
      text-align: center;
    }
  }
`;

const WrapperPricing = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
  ul{
    list-style: none;
    padding: 0px;
    margin: 0;
    display: flex;
    flex-direction: column;
    h2{
      position: absolute;
      bottom: 40px;
      font-size: 40px;
      line-height: 27px;
      span{
        font-size: 20px;
      }
    }
    li{
      display: flex;
      margin-bottom: 5px;
    }
  }
`;

const WrapperColumns = styled.div`
  position: relative;
  width: auto;
  padding-right: 30px;
  padding-left: 30px;
  background: #fff;
  padding-bottom: 30px;
  @media all and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;

    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;


const TrialButton = styled(Button)`
  padding: 20px 40px;
  font-size: 27px;
  margin-bottom: 20px;
  background: #2B549C;
  border-radius: 40px;
`;

const WrapperPricingTrial = styled.div`
  width: 100%;
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  align-items: center;
  > span{
    text-decoration: underline;
    font-weight: bold;
  }
`;